<template>
    <div class="modal" data-keyboard="true" tabindex="-1" role="dialog" :id="`${modalid}-modal`"
      :aria-labelledby="`${modalid}-modal-label`" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" :id="`${modalid}-modal-label`">{{ title }}</h5>
            <button id="close" type="button" class="close" data-dismiss="modal" aria-label="Close"
              @click.prevent="hide(false)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
  
          <div class="modal-body">
            <div>
              <textarea class="form-control miscnotes-textarea" rows="20"
                :value="notes" 
                readonly="true"></textarea>
            </div>
          </div>
  
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" data-dismiss="modal" @click.prevent="hide(false)">
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script lang="ts" setup>
    import $ from 'jquery';
  import { defineProps, defineExpose } from 'vue';
  
  const props = defineProps<{
    modalid: string;
    title?: string;
    notes?: string;
  }>();

  const show = () => {
    if ($(`#${props.modalid}-modal` + 'Backdrop').length == 0) {
      const backdropDiv = $(`<div class="modal-backdrop fade show" id="${props.modalid}-modalBackdrop"></div>`);
      $('body').append(backdropDiv);
      $(`#${props.modalid}-modal`).show();
    }
  }
  const hide = (val: boolean) => {
    if ($(`#${props.modalid}-modal`).length > 0) {
      $(`#${props.modalid}-modal` + 'Backdrop').remove();
      $(`#${props.modalid}-modal`).hide();
    }
  };
  
  defineExpose({ show, hide });
  </script>

  <style scoped>
    .miscnotes-textarea {
        resize: none;
    }
  </style>
  