<template>
  <div @focusout="closeDropdown">
    <singleselect-text
      v-model="inspector"
      track-by="slug"
      label="name"
      :options="sortedinspectorlist"
      :allow-empty="true"
      :taggable="false"
      class="statusdtopdown"
      v-show="changeinspectorpvmid === pvm.id"
      :ref="'dynamicInspectorRefs-' + pvm.id"
      @select="onSelectInspector(pvm.id, $event)"
    >
      <template #clear v-if="pvm.id">
        <i
          @mousedown.prevent.stop="onSelectInspector(pvm.id, '')"
          class="multiselect__clear fa fa-times"
          aria-label="Clear"
        ></i>
      </template>
    </singleselect-text>
    <div
      v-show="changeinspectorpvmid != pvm.id"
      :class="{ 'transparent-text': !pvm.inspector.id }"
      @click="changeStatusReport()"
    >
      {{ pvm.inspector.id ? pvm.inspector.name : "Select Dropdown" }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, toRefs, defineEmits, defineProps, inject } from "vue";
import { useStore } from "vuex";
import { Booking, Inspector, SelectOption } from "@/models";
import moment from "moment";
const store = useStore();
const actProperty: any = inject("actProperty");
const sortedinspectorlist = computed(() => {
  const list = store.getters["pvbookings/inspectorlist"];
  return list.sort((i1: Inspector, i2: Inspector) =>
    i1.name.localeCompare(i2.name)
  );
});
const pvbookings = computed(() => store.getters["pvbookings/getBookingsData"]);
const getBookingsForPI = async (params): Promise<Booking[]> => {
  return await store.dispatch("pvbookings/getBookingsForPI", params);
};
const setPvBookings = (bookings: Booking[]): Promise<Booking[]> => {
  return store.dispatch("pvbookings/setBookings", bookings);
};
const sethasUnsavedChanges = (data: boolean) =>
  store.commit("pvbookings/sethasUnsavedChanges", data);
const props = defineProps<{
  pvm: Booking;
  changeinspectorpvmid: string;
}>();
const emit = defineEmits(["inspectorpvmselected"]);
const dynamicInspectorRefs = ref<{ [key: string]: HTMLElement | undefined }>(
  {}
);
const { pvm, changeinspectorpvmid } = toRefs(props);
const changeStatusReport = () => {
  emit("inspectorpvmselected", pvm.value.id);

  const dropdown = dynamicInspectorRefs.value[
    `dynamicInspectorRefs-${pvm.value.id}`
  ] as any;
  if (dropdown) {
    dropdown.activate();
  }
};
const onSelectInspector = async (id: string | undefined, val: string) => {
  const pmIndex = pvbookings.value.findIndex((r: Booking) => r.id === id);
  pvbookings.value[pmIndex].inspector = val;
  if(pvbookings.value[pmIndex].booking && pvbookings.value[pmIndex].booking?.id) {
    pvbookings.value[pmIndex].booking.inspector = val;
  }
  // if (
  //   pvbookings.value[pmIndex].scheduleddate &&
  //   pvbookings.value[pmIndex].inspector.id
  // ) {
  //   pvbookings.value[pmIndex].status = "Scheduled and in queue";
  // }
  sethasUnsavedChanges(true);
  let booking = pvbookings.value[pmIndex];
  if (booking.scheduleddate && booking.inspector.id) {
    const cachedate = moment(booking.scheduleddate).utc().format("DD-MM-YYYY");
    await getBookingsForPI({
      date: cachedate,
      inspectorid: booking.inspector.id,
      fieldlist: "id,startdate,enddate,jobtype,all_day,address",
      period: "days",
    });
    let inspectorIndex = sortedinspectorlist.value.findIndex(
      (f: Inspector) => f.id === booking.inspector.id
    );
    const stime = actProperty.determineViableSlot(
      booking,
      sortedinspectorlist.value[inspectorIndex]
    );
    let scheduledateAsDate = moment(booking.scheduleddate).utc().toDate();
    booking.startdate = actProperty.prependDate(
      stime,
      scheduledateAsDate,
      scheduledateAsDate
    );
    booking.enddate = moment(booking.startdate)
      .utc()
      .add(30, "minutes")
      .format(actProperty.bookingdateutcformat);
    if (booking.startdate && booking.enddate) {
      let bookings =
        sortedinspectorlist.value[inspectorIndex].bookingsmap.get(cachedate);
        let bIndex = booking?.booking?.id ? bookings.findIndex((f: Booking) => f.id === booking?.booking?.id) : -1;
      if (bIndex === -1) {
        if(booking?.booking && booking.booking?.id){
          let updatebooking = new Booking(booking);
          updatebooking.id = booking.booking?.id;
          booking.booking = updatebooking;
          bookings.push(updatebooking); // Add a copy of the new booking
        } else {
          let newbooking = new Booking(booking);
          newbooking.id = '';
          booking.booking = newbooking;
          bookings.push(newbooking); // Update a copy of the child booking
        } 
      } else {
        let mainBooking = booking;
        mainBooking.id = booking.booking?.id;
        booking.booking = mainBooking;
        bookings[bIndex] = mainBooking; // Update with a copy of the booking
      }
      sortedinspectorlist.value[inspectorIndex].bookingsmap.set(
        cachedate,
        bookings
      );
      // const updateIndex = pvbookings.value.findIndex(
      //   (r: Booking) => r.id === booking.id
      // );
      // if (updateIndex != -1) {
      //   pvbookings.value[pmIndex] = booking;
      //   pvbookings.value[pmIndex].status = 'Scheduled and in queue';
      // }
      setPvBookings(pvbookings.value);
    }
  } else {
    const startTime = "T09:00:00.000Z";
    let date = pvbookings.value[pmIndex].scheduleddate ? pvbookings.value[pmIndex].scheduleddate : pvbookings.value[pmIndex].duedate;
    pvbookings.value[pmIndex].startdate = subjobendDate(startTime, date) ;
    pvbookings.value[pmIndex].enddate = subjobendDate(startTime, date);
    //pvbookings.value[pmIndex].status = "";
  }
  emit("inspectorpvmselected", "");
};
const subjobendDate = (time, date) => {
  let value = "";
  if (time) {
    let dt = date;
    let justdate = moment(dt).utc().format("YYYY-MM-DD");
    let justtime = moment(time, "hh:mm A").format("HH:mm");
    value = `${justdate}T${justtime}:00.000Z`;
  }
  return value;
};

const closeDropdown = () => {
  emit("inspectorpvmselected", "");
};
const inspector = ref<SelectOption>(new SelectOption());
</script>

<style scoped lang="scss">
.emptystatus {
  color: rgb(255, 255, 255);
  background-color: tomato;
}
.transparent-text {
  color: transparent; 
  width: 100%;        
  height: 100%;       
  position: absolute; 
  top: 0;             
  left: 0;            
  cursor: pointer;    
}
</style>

<style lang="scss">
.statusdtopdown > .multiselect__content-wrapper {
  width: fit-content;
}
</style>
