import _, { ValueIteratee } from "lodash";
import _pick from "lodash/pick";
import moment from "moment-timezone";

import { API, Logger } from "aws-amplify";

import axios from "axios";

import { ActionTree, ActionContext } from "vuex";
import { RootState } from "../types";
import { DiaryState } from "./types";
import { Storage } from "aws-amplify";
import { s3Origin } from "@/utilities";

import {
  datetimeToUTC,
  sortInspectorList,
  differenceInSeconds,
  bookingdateformat,
} from "../../utilities";

import {
  Booking,
  GetaddressResponse,
  Email,
  SMS,
  SMSLogs,
  EmailLogs,
  Inspector,
  Location,
  DistanceMatrixResponse,
  TravelTime,
  Report,
  Auditlog,
  Auditlogvaluechange,
  Job,
  Attachments,
  Customer,
  Contact,
  Qcmember,
} from "@/models";
import { SearchAddressResponse } from "@/models/google/SearchAddressResponse";
import { Qcmemberdailytarget } from "@/models/qcmemberdailytarget.model";

// Using API from aws-amplify was resulting in a "no current user" error
// So had to take authentication off the endpoint in cloudformation
// And use axios to make the request without authorisation so it doesn't try
// to use aws-amplify to return a user
// There may be an option to turn this feature off but I couldn't find it
const unAuthenticatedApiRequest = async (
  awsApi: any,
  endpoint: string,
  type = "get",
  opts: any
) => {
  const awsApiConfig = awsApi.configure({});
  // grab the AWS api domain from the config
  const restApiUrl = awsApiConfig.endpoints[0].endpoint + endpoint;
  // console.log('restApiUrl', restApiUrl)
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    ...opts,
  };

  if (type === "post") {
    return axios.post(restApiUrl, options);
  }
  return axios.get(restApiUrl, {
    params: options.queryStringParameters,
  });
};

const unAuthenticatedApiPostRequest = async (awsApi: any, endpoint: string) => {
  return unAuthenticatedApiRequest(awsApi, endpoint, "post", null);
};

const unAuthenticatedApiPutRequest = async (
  awsApi: any,
  endpoint: string,
  payload: any
) => {
  const awsApiConfig = awsApi.configure({});
  // grab the AWS api domain from the config
  const restApiUrl = awsApiConfig.endpoints[0].endpoint + endpoint;
  // console.log('restApiUrl', restApiUrl)
  const options = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios.put(restApiUrl, payload.body);
};

/**
 * Download an attachment (PDF/ZIP) using a Job (polling).
 *
 * @param {string} generateEndpoint Hit first, returns a Job
 * @param {string} downloadEndpoint Hit second, starts processing
 *
 * @returns {Promise} Attachment S3 Key
 */
const bookingAttachmentJob = async (
  generateEndpoint: string,
  downloadEndpoint: string
) => {
  // The first API call returns a Job
  // return API.post('RestAPI', generateEndpoint, null)
  // use unauthenticated post request as the endpoint should require no authorisation
  return unAuthenticatedApiPostRequest(API, generateEndpoint)
    .then((response) => new Job(response.data))
    .then((job: Job) => poll(job, downloadEndpoint))
    .then((payload) => {
      if (payload.file === undefined) {
        throw new Error("Missing Attachment Key");
      }
      return payload.file;
    });
};

/**
 * Polls start/poll endpoints to see if a Job has completed.
 *
 * @param {string} job      Job
 * @param {string} startUrl Hit once to start Job
 *
 * @returns {Promise} Payload object
 */
const poll = async (job: Job, startUrl: string) => {
  // should put these constants into .env
  const POLL_TIMEOUT = 900; // seconds
  const POLL_INTERVAL = 2; // seconds

  const { id } = job;

  let count = 0;
  let errorMessage = "";
  unAuthenticatedApiPostRequest(API, `${startUrl}?token=${id}`).catch((err) => {
    // catch errors returned by pdf download
    // err.reponse will be undefined if API Gateway timeout which we will ignore
    // otherwise we want to return it so we can show back to the user
    if (err.response === undefined) {
      console.log("Ignore API Gateway timeout");
    } else {
      errorMessage = err.response.data.message;
    }
  });

  // Keep polling until the job is complete, or time limit is reached
  while (count * POLL_INTERVAL < POLL_TIMEOUT) {
    count++;

    if (errorMessage) {
      // for PDFs this error is likely to be from the Pug template
      throw new Error(errorMessage);
    }

    // const pollResult = await API.get('RestAPI', `/jobs/${id}`, null);
    const response = await unAuthenticatedApiRequest(
      API,
      `/jobs/${id}`,
      "get",
      null
    );
    const pollResult = response.data;

    console.log(`Poll attempt #${count}`, pollResult);

    if (pollResult.completed === true) {
      if (pollResult.payload === undefined) {
        throw new Error("No Job payload");
      }
      return pollResult.payload;
    }

    await waitForSeconds(POLL_INTERVAL);
  }

  throw new Error("AWS Lambda time limit exceeded.");
};

const updateBookingInStore = (
  store: ActionContext<DiaryState, any>,
  booking: Booking
): Booking[] => {
  if (
    store.state.booking &&
    store.state.booking.id &&
    store.state.booking.id === booking.id
  ) {
    store.state.booking = booking;
  }

  // Get Booking index
  const bookingIndex = store.state.list.findIndex(
    (r) => r._uuid === booking._uuid
  );

  if (bookingIndex == -1) {
    return store.state.list;
  }

  // Update Booking in Bookings list
  return [
    ...store.state.list.slice(0, bookingIndex),
    booking,
    ...store.state.list.slice(bookingIndex + 1),
  ];
};

/**
 * https://stackoverflow.com/questions/33289726/combination-of-async-function-await-settimeout
 *
 * @param seconds - number of seconds to wait
 */
const waitForSeconds = (seconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

/**
 * Actions
 *
 * Actions are similar to mutations, the differences being that:
 * - Instead of mutating the state, actions commit mutations.
 * - Actions can contain arbitrary asynchronous operations.
 * - Actions are triggered with the store.dispatch method e.g. `store.dispatch('getSettings')`
 */
export const actions: ActionTree<DiaryState, RootState> = {
  /**
   * Get multiple Bookings
   *
   * @param {DiaryState} store
   * @param filters
   * @returns Multiple Bookings
   */
  async getBookings(
    store: ActionContext<DiaryState, any>,
    filters: any
  ): Promise<Booking[]> {
    const options = {
      queryStringParameters: _.pickBy(
        _.pick(filters, ["date", "areacode", "inspectorid"])
      ),
    };

    store.commit("app/addRequest", "getBookings", { root: true });

    return API.get("RestAPI", "/bookings", options)
      .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
      .then((bookings: Booking[]) => {
        // Also set bookinglist to each inspector
        // This is used while sorting inspectos according to
        // their area
        let inspectorlist = store.state.inspectorlist;
        if (inspectorlist?.length) {
          inspectorlist.forEach((i: Inspector) => (i.bookings = []));
        }
        bookings.forEach((b: Booking) => {
          b.preserveoriginalvalues();
          if (inspectorlist?.length) {
            inspectorlist.forEach((i: Inspector) => {
              if (i.id === b._inspectorid) {
                i.bookings.push(b);
              }
            });
          }
        });
        store.commit("setInspectors", inspectorlist);
        store.commit("setBookings", bookings);
        return bookings;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getBookings", { root: true })
      );
  },

  /**
   * Get multiple Bookings for given PI for given day
   *
   * @param {DiaryState} store
   * @param filters
   * @returns Multiple Bookings
   */
  async getBookingsForPI(
    store: ActionContext<DiaryState, any>,
    filters: any
  ): Promise<Booking[]> {
    const options = {
      queryStringParameters: _.pickBy(
        _.pick(filters, ["date", "inspectorid", "fieldlist", "period"])
      ),
    };

    return API.get("RestAPI", "/bookings/pi", options)
      .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
      .then((bookings) => {
        store.commit("setInspectorBookings", {
          inspectorid: filters.inspectorid,
          bookings,
        });
        return bookings;
      });
  },

  /**
   * Get Bookings for QC Summary
   *
   * @param {DiaryState} store
   * @param filters
   * @returns Bookings for QC Summary
   */
  async getBookingsforQCSummary(
    store: ActionContext<DiaryState, any>,
    filters: any
  ): Promise<Booking[]> {
    const options = {
      queryStringParameters: _.pickBy(_.pick(filters, ["date"])),
    };

    store.commit("app/addRequest", "getBookingsQCSummary", { root: true });

    return API.get("RestAPI", "/bookings/get-qc-summary", options)
      .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
      .then((bookings: Booking[]) => {
        return bookings;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getBookingsQCSummary", {
          root: true,
        })
      );
  },

  /**
   * Get Bookings for QC MemberList
   *
   * @param {DiaryState} store
   * @param filters
   * @returns Bookings for QC MemberList
   */
  async getBookingsforQCMemberList(
    store: ActionContext<DiaryState, any>,
    filters: any
  ): Promise<Qcmember[]> {
    const options = {
      queryStringParameters: _.pickBy(_.pick(filters, ["date"])),
    };

    store.commit("app/addRequest", "getBookingsforQCMemberList", {
      root: true,
    });

    return API.get("RestAPI", "/get-qc-members-list", options)
      .then((response) =>
        response.map((x: Partial<Qcmember>) => new Qcmember(x))
      )
      .then((qcmembers: Qcmember[]) => {
        return qcmembers;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getBookingsforQCMemberList", {
          root: true,
        })
      );
  },

  /**
   * Get Daily Target
   *
   * @param {DiaryState} store
   * @param filters
   * @returns Get Daily Target
   */
  async getQcDailyTarget(
    store: ActionContext<DiaryState, any>,
    filters: any
  ): Promise<Qcmemberdailytarget[]> {
    const options = {
      queryStringParameters: _.pickBy(_.pick(filters, ["date"])),
    };

    store.commit("app/addRequest", "getQcDailyTarget", { root: true });

    return API.get("RestAPI", "/get-members-daily-target", options)
      .then((qcs: Qcmemberdailytarget[]) => {
        return qcs;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getQcDailyTarget", { root: true })
      );
  },

  /**
   * updateDailyTarget
   *
   * @param {DiaryState} store
   * @param qcdailytarget Qcmemberdailytarget
   */
  async updateDailyTarget(
    store: ActionContext<DiaryState, any>,
    qcdailytarget: Qcmemberdailytarget
  ): Promise<Qcmemberdailytarget> {
    store.commit("app/addRequest", "updateDailyTarget", { root: true });
    return API.post("RestAPI", `/update-daily-target`, {
      body: qcdailytarget,
    })
      .then((response) => new Qcmemberdailytarget(response))
      .finally(() =>
        store.commit("app/removeRequest", "updateDailyTarget", { root: true })
      );
  },
  /**
   * Get multiple Bookings with given id list
   *
   * @param {DiaryState} store
   * @param filters
   * @returns Multiple Bookings
   */
  async getBookingsWithIdlist(
    store: ActionContext<DiaryState, any>,
    idlist: string[]
  ): Promise<Booking[]> {
    const options = {
      queryStringParameters: {
        idlist: encodeURI(idlist.join(",")),
      },
    };

    store.commit("app/addRequest", "getBookingsWithIdlist", { root: true });

    return API.get("RestAPI", "/bookings", options)
      .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
      .then((bookings: Booking[]) => {
        // Also set bookinglist to each inspector
        // This is used while sorting inspectos according to
        // their area
        let inspectorlist = store.state.inspectorlist;
        // if (inspectorlist?.length) {
        //   inspectorlist.forEach((i: Inspector) => (i.bookings = []));
        // }
        let newBookings = [];
        bookings.forEach((b: Booking) => {
          b.preserveoriginalvalues();
          if (inspectorlist?.length) {
            inspectorlist.forEach((i: Inspector) => {
              if (i.id === b._inspectorid) {
                let index = i.bookings.findIndex((f: Booking) => f.id === b.id);
                let sindex = store.state.list.findIndex(
                  (f: Booking) => f.id === b.id
                );
                if (index == -1) {
                  i.bookings.push(b);
                } else {
                  i.bookings[index] = b;
                }
                if (sindex == -1) {
                  newBookings.push(b);
                } else {
                  store.state.list[sindex] = b;
                }
              }
            });
          }
        });
        store.commit("setBookings", [...store.state.list, ...newBookings]);
        return bookings;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getBookingsWithIdlist", {
          root: true,
        })
      );
  },

  /**
   * Get all  bookings for given inspector and given date
   *
   * @param {DiaryState} store
   * @param filters
   * @returns Multiple Bookings
   */
  async getBookingsForInspectorWithDate(
    store: ActionContext<DiaryState, any>,
    filters: any
  ): Promise<Booking[]> {
    const options = {
      queryStringParameters: _.pickBy(_.pick(filters, ["date", "inspectorid"])),
    };

    store.commit("app/addRequest", "getBookingsForInspectorWithDate", {
      root: true,
    });

    return API.get("RestAPI", "/bookings", options)
      .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
      .then((bookings: Booking[]) => {
        return bookings;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getBookingsForInspectorWithDate", {
          root: true,
        })
      );
  },

  /**
   * Get all unread smslogs
   *
   * @param {DiaryState} store
   * @param filters
   * @returns Multiple Bookings
   */
  async getUnreadSMSLogs(
    store: ActionContext<DiaryState, any>,
    params: any
  ): Promise<Booking[]> {
    store.commit("app/addRequest", "getUnreadSMSLogs", {
      root: true,
    });

    return API.get("RestAPI", "/bookings/getunreadsmslogs", {
      queryStringParameters: params,
    })
      .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
      .then((bookings: Booking[]) => {
        return bookings;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getUnreadSMSLogs", {
          root: true,
        })
      );
  },

  /**
   * Get all unread emaillogs
   *
   * @param {DiaryState} store
   * @param filters
   * @returns Multiple Bookings
   */
  async getUnreadEmailLogs(
    store: ActionContext<DiaryState, any>,
    params: any
  ): Promise<Booking[]> {
    store.commit("app/addRequest", "getUnreadEmailLogs", {
      root: true,
    });

    return API.get("RestAPI", "/bookings/getunreademaillogs", {
      queryStringParameters: params,
    })
      .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
      .then((bookings: Booking[]) => {
        return bookings;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getUnreadEmailLogs", {
          root: true,
        })
      );
  },

  /**
   * Get all  bookings for given inspector and given date
   *
   * @param {DiaryState} store
   * @param filters
   * @returns Multiple Bookings
   */
  async getBookingsUnauthenticatedForInspectorWithDate(
    store: ActionContext<DiaryState, any>,
    filters: any
  ): Promise<Booking[]> {
    const options = {
      queryStringParameters: _.pickBy(_.pick(filters, ["date", "inspectorid"])),
    };
    return unAuthenticatedApiRequest(API, "/unauth/bookings", "get", options)
      .then((response) =>
        response.data.map((x: Partial<Booking>) => new Booking(x))
      )
      .then((bookings: Booking[]) => {
        return bookings;
      });
  },
  /**
   * Get all  bookings for given inspector and given date
   *
   * @param {DiaryState} store
   * @param filters
   * @returns Multiple Bookings
   */
  async getBookingsCloneUnauthenticatedForInspectorWithDate(
    store: ActionContext<DiaryState, any>,
    filters: any
  ): Promise<Booking[]> {
    store.commit("app/addRequest", "getBookingsCloneUnauthenticatedForInspectorWithDate", { root: true });
    const options = {
      queryStringParameters: _.pickBy(_.pick(filters, ["date", "inspectorid"])),
    };
    return unAuthenticatedApiRequest(API, "/unauth/bookings", "get", options)
      .then((response) =>
        response.data.map((x: Partial<Booking>) => new Booking(x))
      )
      .then((bookings: Booking[]) => {
        return bookings;
      }).finally(() =>
        store.commit("app/removeRequest", "getBookingsCloneUnauthenticatedForInspectorWithDate", { root: true })
      );
  },

  /**
   * Get one Booking
   *
   * @param {DiaryState} store
   * @param id
   * @returns Individual Booking
   */
  async getBooking(store: ActionContext<DiaryState, any>, id) {
    // New Booking
    if (id === "new") {
      const booking = new Booking();
      return booking;
    }
    // Search by Ref
    store.commit("app/addRequest", "getBooking", { root: true });
    return API.get("RestAPI", `/bookings/${id}`, null)
      .then((response) => new Booking(response))
      .then((booking: Booking) => {
        booking.preserveoriginalvalues();
        store.commit("setBooking", booking);
        let index = store.state.inspectorlist.findIndex(
          (insp: Inspector) => insp.id == booking.inspector.id
        );
        if (index >= 0) {
          let inspector: Inspector = store.state.inspectorlist[index];
          store.state.inspectorlist.splice(index, 1);
          store.state.inspectorlist.unshift(inspector);
          store.commit("setInspectors", store.state.inspectorlist);
        }
        return booking;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getBooking", { root: true })
      );
  },

  /**
   * Get one Booking
   *
   * @param {DiaryState} store
   * @param id
   * @returns Individual Booking for unauthenticated user
   */
  async getBookingUnauthenticated(store: ActionContext<DiaryState, any>, id) {
    return unAuthenticatedApiRequest(API, `/unauth/bookings/${id}`, "get", null)
      .then((response) => new Booking(response.data))
      .then((booking: Booking) => {
        return booking;
      });
  },

  /**
   * Get one Booking
   *
   * @param {DiaryState} store
   * @param id
   * @returns Individual Booking
   */
  async getBookingWithoutStoringInState(
    store: ActionContext<DiaryState, any>,
    payload: { id: string; cancelled: string }
  ) {
    // Search by Ref
    store.commit(
      "app/addRequest",
      { id: "getBookingWithoutStoringInState", blocking: false },
      {
        root: true,
      }
    );
    return API.get(
      "RestAPI",
      `/bookings/${payload.id}?cancelled=${payload.cancelled}`,
      null
    )
      .then((response) => {
        let newbooking = new Booking(response);
        const index = store.state.parkedbookings.findIndex(
          (n) => n.id === newbooking.id
        );
        if (index >= 0) {
          const newlist = [...store.state.parkedbookings];
          newlist.splice(index, 1);
          newlist.push(newbooking);
          store.commit("setParkBookings", newlist);
        }
        return newbooking;
      })
      .catch((err: any) => new Booking())
      .finally(() =>
        store.commit("app/removeRequest", "getBookingWithoutStoringInState", {
          root: true,
        })
      );
  },

  async audit(
    store: ActionContext<DiaryState, any>,
    payload: { booking: Booking; operation: string; subbooking?: Booking }
  ): Promise<Booking> {
    if (
      payload.booking &&
      (payload.operation === "add" || payload.booking.id)
    ) {
      const email: string = store.rootGetters["auth/email"];
      // Check if we need to add auditlogs
      if (payload.operation === "add") {
        const auditlog: Auditlog = new Auditlog({
          datetime: moment(new Date()).utc().format(bookingdateformat),
          user: email,
          notes: "Added booking",
        });
        payload.booking.auditlogs.push(auditlog);
      } else if (payload.operation === "cancel") {
        let notes: string = "Cancelled booking";
        if (payload.subbooking) {
          notes = `Cancelled ${payload.subbooking.subtype} type sub-booking. It was originally scheduled on ${payload.subbooking.bookingdate} ${payload.subbooking.starttime}.`;
        }
        const auditlog: Auditlog = new Auditlog({
          datetime: moment(new Date()).utc().format(bookingdateformat),
          user: email,
          notes,
        });
        payload.booking.auditlogs.push(auditlog);
      } else if (payload.operation === "restore") {
        const auditlog: Auditlog = new Auditlog({
          datetime: moment(new Date()).utc().format(bookingdateformat),
          user: email,
          notes: "Restored booking",
        });
        payload.booking.auditlogs.push(auditlog);
      } else if (payload.operation === "update") {
        let changelist: Map<string, string> = payload.booking.changelist();
        if (changelist && changelist.size) {
          const auditlog: Auditlog = new Auditlog({
            datetime: moment(new Date()).utc().format(bookingdateformat),
            user: email,
            valuechanges: [],
          });
          changelist.forEach((value: string, k: string) => {
            let newvalue: any = _.get(payload.booking, k, "");
            newvalue = Booking.formatFieldValue(k, newvalue);
            if (k === "issued") {
              value = value ? "Yes" : "No";
              newvalue = newvalue ? "Yes" : "No";
            }
            const valuechange = new Auditlogvaluechange({
              fieldname: k,
              oldvalue: value,
              newvalue: newvalue,
            });
            auditlog.valuechanges.push(valuechange);
          });
          payload.booking.auditlogs.push(auditlog);
          payload.booking.preserveoriginalvalues();
        }
      }
    }
    return payload.booking;
  },

  /**
   * Add a Booking
   *
   * @param {DiaryState} store
   * @param booking Booking
   * @returns Booking
   */
  async addBooking(store: ActionContext<DiaryState, any>, booking: Booking) {
    store.commit("app/addRequest", "addBooking", { root: true });
    await store
      .dispatch("audit", { booking: new Booking(booking), operation: "add" })
      .then((b: Booking) => (booking = b));
    let jsonBody = booking.toJSON();
    return await API.post("RestAPI", `/booking`, { body: jsonBody })
      .then((response) => new Booking(response))
      .then(async (booking: Booking) => {
        // Registert this booking with master property record
        await API.put(
          "RestAPI",
          `/bookings/registertomaster/${booking.id}`,
          {}
        );
        return booking;
      })
      .then(async (booking: Booking) => {
        if (booking.jobtype === "checkout" || booking.jobtype === "soc") {
          // Connect the new booking to the next in line CI if this is a CO or SOC
          let propertybookings: Booking[] = await store.dispatch(
            "property/getPropertyBookings",
            booking.id,
            { root: true }
          );
          propertybookings = propertybookings
            .filter((b) => !b.cancelled)
            .sort((b1: Booking, b2: Booking) => {
              if (moment.utc(b1.startdate).isAfter(moment.utc(b2.startdate))) {
                return 1;
              } else if (
                moment.utc(b1.startdate).isBefore(moment.utc(b2.startdate))
              ) {
                return -1;
              } else {
                return 0;
              }
            });
          if (propertybookings.length > 0) {
            let currentBookingIndex = propertybookings.findIndex(
              (f: Booking) => f.id === booking.id
            );
            if (
              currentBookingIndex !== -1 &&
              currentBookingIndex < propertybookings.length - 1
            ) {
              let nextBooking: Booking =
                propertybookings[currentBookingIndex + 1];
              if (nextBooking && nextBooking?.id) {
                if (nextBooking?.jobtype === "checkin") {
                  nextBooking.previousbooking = booking;
                  await store.dispatch(
                    "diary/savePreviousBookingFromLink",
                    nextBooking,
                    { root: true }
                  );
                }
                if (
                  nextBooking?.jobtype === "checkin" &&
                  (nextBooking?.internaljobtype == Booking.CIOFFACTREPORT ||
                    nextBooking?.internaljobtype == Booking.B2BCI)
                ) {
                  nextBooking.connectedbooking = booking;
                  await store.dispatch(
                    "diary/saveConnectedBooking",
                    nextBooking,
                    { root: true }
                  );
                  booking.connectedbooking = nextBooking;
                  await store.dispatch("diary/saveConnectedBooking", booking, {
                    root: true,
                  });
                }
              }
            }
          }
        }
        return booking;
      })
      .then((booking: Booking) => {
        store.commit("setBookings", store.state.list.concat(booking));
        store.commit("resetUnsavedChanges");
        return booking;
      })
      .finally(() =>
        store.commit("app/removeRequest", "addBooking", { root: true })
      );
  },

  /**
   * Add multiple Bookings
   *
   * @param {DiaryState} store
   * @param bookings: Booking[]
   * @returns Booking[]
   */
  async addBookings(
    store: ActionContext<DiaryState, any>,
    bookings: Booking[]
  ) {
    store.commit("app/addRequest", "addBookings", { root: true });
    let jsonBody: any = [];
    bookings.forEach((booking: Booking) => jsonBody.push(booking.toJSON()));
    return API.post("RestAPI", `/bookings`, { body: jsonBody })
      .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
      .finally(() =>
        store.commit("app/removeRequest", "addBookings", { root: true })
      );
  },

  /**
   * Import google calendar events
   *
   * @param {DiaryState} store
   * @param googlebooking: any[]
   * @returns any[]
   */
  async importGoogleBookings(
    store: ActionContext<DiaryState, any>,
    bookings: any[]
  ) {
    store.commit("app/addRequest", "importGoogleBookings", { root: true });
    let jsonBody: any = [];
    bookings.forEach((booking: Booking) => jsonBody.push(booking));
    return API.post("RestAPI", `/bookings`, { body: jsonBody })
      .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
      .finally(() =>
        store.commit("app/removeRequest", "importGoogleBookings", {
          root: true,
        })
      );
  },

  async shareBooking(
    store: ActionContext<DiaryState, any>,
    payload: { booking: Booking; idlist: string[] }
  ): Promise<string[]> {
    store.commit("app/addRequest", "shareBooking", { root: true });
    let jsonBody = payload.booking.toJSON();
    const encodedidlist = encodeURI(payload.idlist.join(","));
    return await API.post("RestAPI", `/sharebooking/${encodedidlist}`, {
      body: jsonBody,
    })
      .then((response) => {
        var list: Booking[] = [];
        if (response?.length > 0) {
          list = response.map((b: Booking) => new Booking(b));
        }
        return list.map((b) => b.id);
      })
      .finally(() =>
        store.commit("app/removeRequest", "shareBooking", { root: true })
      );
  },

  /**
   * Update a Booking
   *
   * @param {DiaryState} store
   * @param booking Booking
   * @returns Updated Booking
   */
  updateBooking(store: ActionContext<DiaryState, any>, booking: Booking) {
    // Don't call backend API yet, these bookings are saved locally in store and then mass saved
    booking.updated = true;
    _.remove(store.state.list, (c: Booking) => c.id === booking.id);
    store.commit("setBookings", store.state.list.concat(booking));
    //store.commit("setBooking", booking);
    store.commit("addUnsavedChange");
    return booking;
  },

  /**
   * Save booking changes
   *
   * @param {DiaryState} store
   * @param booking Booking
   */
  async saveBooking(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<Booking> {
    if (booking && booking.id) {
      store.commit("app/addRequest", "saveBooking", { root: true });
      await store
        .dispatch("audit", {
          booking: new Booking(booking),
          operation: "update",
        })
        .then((b: Booking) => (booking = b));
      return (
        API.put("RestAPI", `/bookings/${booking.id}`, {
          body: booking.toJSON(),
        })
          .then((response) => new Booking(response))
          .then((booking: Booking) => {
            booking.preserveoriginalvalues();
            if (!booking.cancelled) {
              _.remove(store.state.list, (c: Booking) => c.id === booking.id);
              store.commit("setBookings", store.state.list.concat(booking));
            }
            if (store.state.booking.id === booking.id) {
              store.state.booking = booking;
            }
            return booking;
          })
          // .then(async (booking: Booking) => {
          //   // Registert this booking with master property record
          //   await API.put(
          //     "RestAPI",
          //     `/bookings/registertomaster/${booking.id}`,
          //     {}
          //   );
          //   return booking;
          // })
          .finally(() =>
            store.commit("app/removeRequest", "saveBooking", { root: true })
          )
      );
    }
    return booking;
  },

  /**
   * Save booking changes, this is mainly used from scheduler
   *
   * @param {DiaryState} store
   * @param booking Booking
   */
  async saveBookingFromScheduler(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<Booking> {
    if (booking && booking.id) {
      store.commit("app/addRequest", "saveBooking", { root: true });
      await store
        .dispatch("audit", {
          booking: new Booking(booking),
          operation: "update",
        })
        .then((b: Booking) => (booking = b));
      let json = _pick(booking.toJSON(), [
        "startdate",
        "enddate",
        "inspector",
        "preferredappointmenttime",
        "auditlogs",
        "traveltime",
        "recurrence_excl_dates",
        "recurrence_rule",
        "address",
        "keypickup",
        "keypickupfromaddress",
        "releasekeysto",
        "keyreleasetoaddress",
        "titlenotes",
        "preferredduration",
      ]);
      return API.put("RestAPI", `/bookings/${booking.id}`, {
        body: json,
      })
        .then((response) => new Booking(response))
        .then((booking: Booking) => {
          booking.preserveoriginalvalues();
          _.remove(store.state.list, (c: Booking) => c.id === booking.id);
          store.commit("setBookings", store.state.list.concat(booking));
          if (store.state.booking.id === booking.id) {
            store.state.booking = booking;
          }
          return booking;
        })
        .finally(() =>
          store.commit("app/removeRequest", "saveBooking", { root: true })
        );
    }
    return booking;
  },

  /**
   * Save booking changes, this is mainly used from scheduler
   *
   * @param {DiaryState} store
   * @param booking Booking
   */
  async savePreviousBookingFromLink(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<Booking> {
    if (booking && booking.id) {
      store.commit("app/addRequest", "saveBooking", { root: true });
      let json = _pick(booking.toJSON(), [
        "previousbooking",
        "startdate",
        "enddate",
        "inspector",
        "address",
        "keypickup",
        "keypickupfromaddress",
        "releasekeysto",
        "keyreleasetoaddress",
      ]);
      return API.put("RestAPI", `/bookings/${booking.id}`, {
        body: json,
      })
        .then((response) => new Booking(response))
        .then((booking: Booking) => {
          booking.preserveoriginalvalues();
          _.remove(store.state.list, (c: Booking) => c.id === booking.id);
          store.commit("setBookings", store.state.list.concat(booking));
          if (store.state.booking.id === booking.id) {
            store.state.booking = booking;
          }
          return booking;
        })
        .finally(() =>
          store.commit("app/removeRequest", "saveBooking", { root: true })
        );
    }
    return booking;
  },

  /**
   * Save booking changes, this is mainly used from scheduler
   *
   * @param {DiaryState} store
   * @param booking Booking
   */
  async saveConnectedBooking(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<Booking> {
    if (booking && booking.id) {
      store.commit("app/addRequest", "saveBooking", { root: true });
      let json = _pick(booking.toJSON(), [
        "connectedbooking",
        "startdate",
        "enddate",
        "inspector",
        "address",
        "keypickup",
        "keypickupfromaddress",
        "releasekeysto",
        "keyreleasetoaddress",
      ]);
      return API.put("RestAPI", `/bookings/${booking.id}`, {
        body: json,
      })
        .then((response) => new Booking(response))
        .then((booking: Booking) => {
          booking.preserveoriginalvalues();
          _.remove(store.state.list, (c: Booking) => c.id === booking.id);
          store.commit("setBookings", store.state.list.concat(booking));
          if (store.state.booking.id === booking.id) {
            store.state.booking = booking;
          }
          return booking;
        })
        .finally(() =>
          store.commit("app/removeRequest", "saveBooking", { root: true })
        );
    }
    return booking;
  },

  /**
   * Save booking changes, this is mainly used from scheduler
   *
   * @param {DiaryState} store
   * @param booking Booking
   */
  async removePreviousBookingFromLink(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<Booking> {
    if (booking && booking.id) {
      store.commit("app/addRequest", "saveBooking", { root: true });
      let json = _pick(booking.toJSON(), [
        "previousbooking",
        "startdate",
        "enddate",
        "inspector",
        "address",
        "keypickup",
        "keypickupfromaddress",
        "releasekeysto",
        "keyreleasetoaddress",
      ]);
      return API.put("RestAPI", `/bookings/${booking.id}`, {
        body: json,
      })
        .then((response) => new Booking(response))
        .then((booking: Booking) => {
          booking.preserveoriginalvalues();
          _.remove(store.state.list, (c: Booking) => c.id === booking.id);
          store.commit("setBookings", store.state.list.concat(booking));
          if (store.state.booking.id === booking.id) {
            store.state.booking = booking;
          }
          return booking;
        })
        .finally(() =>
          store.commit("app/removeRequest", "saveBooking", { root: true })
        );
    }
    return booking;
  },

  /**
   * Update connected booking, mainly used for Back to Back CI connected booking
   *
   * @param {DiaryState} store
   * @param booking Booking
   */
  async updateConnectedBooking(
    store: ActionContext<DiaryState, any>,
    id: string
  ): Promise<Booking> {
    if (id) {
      store.commit("app/addRequest", "updateConnectedBooking", { root: true });
      return API.put("RestAPI", `/connectedbookings/${id}`, {})
        .then((response) => new Booking(response))
        .then((booking: Booking) => {
          //booking.preserveoriginalvalues();
          // store.commit("updateBooking", booking);
          return booking;
        })
        .finally(() =>
          store.commit("app/removeRequest", "updateConnectedBooking", {
            root: true,
          })
        );
    }
  },

  /**
   * Save booking changes unauthenticated
   *
   * @param {DiaryState} store
   * @param booking Booking
   */
  async saveTenantResponse(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<Booking> {
    return unAuthenticatedApiPutRequest(API, `/unauth/bookings/${booking.id}`, {
      body: booking.toJSON(),
    }).then((response) => new Booking(response.data));
  },
  /**
   * Save booking changes unauthenticated
   *
   * @param {DiaryState} store
   * @param booking Booking
   */
  async saveCloneTenantResponse(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<Booking> {
    store.commit("app/addRequest", "saveCloneTenantResponse", { root: true });
    return unAuthenticatedApiPutRequest(API, `/unauth/bookings/${booking.id}`, {
      body: booking.toJSON(),
    }).then((response) => {
      _.remove(store.state.list, (c: Booking) => c.id === booking.id);
      store.commit("setBookings", store.state.list.concat(booking));
      return new Booking(response.data)
    }).finally(() => {
      store.commit("app/removeRequest", "saveCloneTenantResponse", { root: true });
    });
  },

  /**
   * Save booking outside store
   *
   * @param {DiaryState} store
   * @param booking Booking
   */
  async saveBookingOutsideStore(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<Booking> {
    return API.put("RestAPI", `/bookings/${booking.id}`, {
      body: booking.toJSON(),
    }).then((response) => new Booking(response.data));
  },

  /**
   * Update current Booking changes, this is mainly used from diary form
   *
   * @param {DiaryState} store
   */
  async saveAllBookingChanges(store: ActionContext<DiaryState, any>) {
    store.commit("app/addRequest", "updateBooking", { root: true });
    let booking: Booking = new Booking(store.state.booking);
    booking.attachments = new Attachments();
    await store
      .dispatch("audit", { booking: new Booking(booking), operation: "update" })
      .then((b: Booking) => (booking = b));
    await API.put("RestAPI", `/bookings/${booking.id}`, {
      body: booking.toJSON(),
    }).finally(() => {
      booking.preserveoriginalvalues();
      store.commit("setBooking", booking);
      store.commit("app/removeRequest", "updateBooking", { root: true });
      store.commit("resetUnsavedChanges");
    });
    return;
  },

  /**
   * Update tenant email confirmation sent date
   *
   * @param {DiaryState} store
   * @param bookingid string
   * @returns void
   */
  async updateTenantEmailConfirmationSent(
    store: ActionContext<DiaryState, any>,
    bookingid: string
  ): Promise<void> {
    store.commit("app/addRequest", "updateTenantEmailConfirmationSent", {
      root: true,
    });
    if (bookingid) {
      let booking: Booking = new Booking();
      booking.id = bookingid;
      booking.emaillogs = new EmailLogs();
      booking.emaillogs.tenantconfirmationsentdate = datetimeToUTC(new Date());
      booking.appointmentchanged = false;
      API.put("RestAPI", `/bookings/tenantconfirmation/${booking.id}`, {
        body: booking.toJSON(),
      })
        .then((response) => new Booking(response))
        .then((booking: Booking) => {
          store.commit("updateTenantEmailConfirmationSent", booking);
          store.commit("setAppointmentchanged", booking.appointmentchanged);
          return booking;
        })
        .finally(() =>
          store.commit(
            "app/removeRequest",
            "updateTenantEmailConfirmationSent",
            { root: true }
          )
        );
    }
    return;
  },

  /**
   * Update tenant email confirmation sent date
   *
   * @param {DiaryState} store
   * @param bookingid string
   * @returns void
   */
  async updateIssueInvoiceEmailSent(
    store: ActionContext<DiaryState, any>,
    bookingid: string
  ): Promise<void> {
    store.commit("app/addRequest", "updateIssueInvoiceEmailSent", {
      root: true,
    });
    if (bookingid) {
      let booking: Booking = new Booking();
      booking.id = bookingid;
      booking.emaillogs = new EmailLogs();
      booking.emaillogs.invoicesentdate = datetimeToUTC(new Date());
      booking.appointmentchanged = false;
      API.put("RestAPI", `/bookings/issueinvoice/${booking.id}`, {
        body: booking.toJSON(),
      })
        .then((response) => new Booking(response))
        .then((booking: Booking) => {
          store.commit("updateIssueInvoiceEmailSent", booking);
          store.commit("setAppointmentchanged", booking.appointmentchanged);
          return booking;
        })
        .finally(() =>
          store.commit("app/removeRequest", "updateIssueInvoiceEmailSent", {
            root: true,
          })
        );
    }
    return;
  },

  /**
   * Update tenant sms confirmation sent date
   *
   * @param {DiaryState} store
   * @param bookingid string
   * @returns void
   */
  async updateTenantSMSConfirmationSent(
    store: ActionContext<DiaryState, any>,
    bookingid: string
  ): Promise<void> {
    store.commit("app/addRequest", "updateTenantSMSConfirmationSent", {
      root: true,
    });
    if (bookingid) {
      let booking: Booking = new Booking();
      booking.id = bookingid;
      booking.smslogs = new SMSLogs();
      booking.smslogs.tenantconfirmationsentdate = datetimeToUTC(new Date());
      booking.appointmentchanged = false;
      API.put("RestAPI", `/bookings/tenantconfirmation/${booking.id}`, {
        body: booking.toJSON(),
      })
        .then((response) => new Booking(response))
        .then((booking: Booking) => {
          store.commit("updateTenantSMSConfirmationSent", booking);
          store.commit("setAppointmentchanged", booking.appointmentchanged);
          return booking;
        })
        .finally(() =>
          store.commit("app/removeRequest", "updateTenantSMSConfirmationSent", {
            root: true,
          })
        );
    }
    return;
  },

  /**
   * Update a client email confirmation sent date
   *
   * @param {DiaryState} store
   * @param bookingid: string
   * @returns void
   */
  async updateClientEmailConfirmationSent(
    store: ActionContext<DiaryState, any>,
    bookingid: string
  ): Promise<void> {
    store.commit("app/addRequest", "updateClientEmailConfirmationSent", {
      root: true,
    });
    if (bookingid) {
      let booking: Booking = new Booking();
      booking.id = bookingid;
      booking.emaillogs = new EmailLogs();
      booking.emaillogs.clientconfirmationsentdate = datetimeToUTC(new Date());
      API.put("RestAPI", `/bookings/clientconfirmation/${booking.id}`, {
        body: booking.toJSON(),
      })
        .then((response) => new Booking(response))
        .then((booking: Booking) => {
          store.commit("updateClientEmailConfirmationSent", booking);
          return booking;
        })
        .finally(() =>
          store.commit(
            "app/removeRequest",
            "updateClientEmailConfirmationSent",
            { root: true }
          )
        );
    }
    return;
  },

  /**
   * Update a landlord email confirmation sent date
   *
   * @param {DiaryState} store
   * @param bookingid: string
   * @returns void
   */
  async updateLandlordEmailConfirmationSent(
    store: ActionContext<DiaryState, any>,
    bookingid: string
  ): Promise<void> {
    store.commit("app/addRequest", "updateLandlordEmailConfirmationSent", {
      root: true,
    });
    if (bookingid) {
      let booking: Booking = new Booking();
      booking.id = bookingid;
      booking.emaillogs = new EmailLogs();
      booking.emaillogs.landlordconfirmationsentdate = datetimeToUTC(
        new Date()
      );
      API.put("RestAPI", `/bookings/landlordconfirmation/${booking.id}`, {
        body: booking.toJSON(),
      })
        .then((response) => new Booking(response))
        .then((booking: Booking) => {
          store.commit("updateLandlordEmailConfirmationSent", booking);
          return booking;
        })
        .finally(() =>
          store.commit(
            "app/removeRequest",
            "updateLandlordEmailConfirmationSent",
            { root: true }
          )
        );
    }
    return;
  },

  /**
   * Update a Booking
   *
   * @param {DiaryState} store
   * @param booking Booking
   * @returns void
   */
  async updateTenantConfirmation(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<void> {
    store.commit("app/addRequest", "updateTenantConfirmation", { root: true });
    if (booking) {
      API.put("RestAPI", `/bookings/tenantconfirmation/${booking.id}`, {
        body: booking.toJSON(),
      });
    }
    store.commit("app/removeRequest", "updateTenantConfirmation", {
      root: true,
    });
    store.commit("resetUnsavedChanges");
    return;
  },

  /**
   * Update dataentry email confirmation sent date
   *
   * @param {DiaryState} store
   * @param bookingid string
   * @returns void
   */
  async updateDataentryEmailConfirmationSent(
    store: ActionContext<DiaryState, any>,
    bookingid: string
  ): Promise<void> {
    store.commit("app/addRequest", "updateDataentryEmailConfirmationSent", {
      root: true,
    });
    if (bookingid) {
      let booking: Booking = new Booking();
      booking.id = bookingid;
      booking.emaillogs = new EmailLogs();
      booking.emaillogs.dataentryconfirmationsentdate = datetimeToUTC(
        new Date()
      );
      booking.appointmentchanged = false;
      API.put("RestAPI", `/bookings/dataentryconfirmation/${booking.id}`, {
        body: booking.toJSON(),
      })
        .then((response) => new Booking(response))
        .then((booking: Booking) => {
          store.commit("updateDataentryEmailConfirmationSent", booking);
          store.commit("setAppointmentchanged", booking.appointmentchanged);
          return booking;
        })
        .finally(() =>
          store.commit(
            "app/removeRequest",
            "updateDataentryEmailConfirmationSent",
            { root: true }
          )
        );
    }
    return;
  },

  /**
   * Update dataentry job acknowledge
   *
   * @param {DiaryState} store
   * @param bookingid string
   * @returns void
   */
  async updateDataentryAcknowledge(
    store: ActionContext<DiaryState, any>,
    bookingid: string
  ): Promise<void> {
    store.commit("app/addRequest", "updateDataentryAcknowledge", {
      root: true,
    });
    if (bookingid) {
      let booking: Booking = new Booking();
      booking.id = bookingid;
      booking.emaillogs = new EmailLogs();
      booking.emaillogs.dataentryacknowledgementreceiveddate = datetimeToUTC(
        new Date()
      );
      booking.appointmentchanged = false;
      API.put("RestAPI", `/bookings/dataentryacknowledge/${booking.id}`, {
        body: booking.toJSON(),
      })
        .then((response) => new Booking(response))
        .then((booking: Booking) => {
          store.commit("updateDataentryAcknowledge", booking);
          store.commit("setAppointmentchanged", booking.appointmentchanged);
          return booking;
        })
        .finally(() =>
          store.commit("app/removeRequest", "updateDataentryAcknowledge", {
            root: true,
          })
        );
    }
    return;
  },

  /**
   * Delete a Booking
   *
   * @param {DiaryState} store
   * @param booking Booking
   * @returns void
   */
  async deleteBooking(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<void> {
    store.commit("app/addRequest", "deleteBooking", { root: true });
    return API.del("RestAPI", `/bookings/${booking.id}`, {})
      .then(() =>
        store.commit(
          "setBookings",
          _.remove(store.state.list, (c: Booking) => c.id !== booking.id)
        )
      )
      .then(() => store.commit("resetUnsavedChanges"))
      .finally(() =>
        store.commit("app/removeRequest", "deleteBooking", { root: true })
      );
  },

  /**
   * Cancel a Booking
   *
   * @param {DiaryState} store
   * @param booking Booking
   * @returns void
   */
  async cancelBooking(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<void> {
    store.commit("app/addRequest", "cancelBooking", { root: true });
    await store
      .dispatch("audit", { booking: booking, operation: "cancel" })
      .then((b: Booking) => (booking = b));
    return await API.put("RestAPI", `/bookings/cancel/${booking.id}`, {})
      //.then(async () => await API.put('RestAPI', `/bookings/${booking.id}`, { body: booking.toJSON() }))
      // Remove cancelled booking from leadbooking
      .then(async (response) => {
        if (response && response.booking.jobtype == "property visit") {
          let newBooking = new Booking(response.booking);
          newBooking.auditlogs = booking.auditlogs;
          booking = newBooking;
        }
        let json = _pick(booking.toJSON(), [
          "auditlogs",
          "startdate",
          "enddate",
          "inspector",
          "address",
          "keypickup",
          "keypickupfromaddress",
          "releasekeysto",
          "keyreleasetoaddress",
          "cancelnotes",
        ]);
        await API.put("RestAPI", `/bookings/${booking.id}`, {
          body: json,
        });
      })
      .then(async () => {
        // remove link booking
        if (booking?.jobtype === "checkout" || booking?.jobtype === "soc") {
          // Connect the new booking to the next in line CI if this is a CO or SOC
          let propertybookings: Booking[] = await store.dispatch(
            "property/getPropertyBookings",
            booking.id,
            { root: true }
          );

          propertybookings.sort((b1: Booking, b2: Booking) => {
            if (moment.utc(b1.startdate).isAfter(moment.utc(b2.startdate))) {
              return 1;
            } else if (
              moment.utc(b1.startdate).isBefore(moment.utc(b2.startdate))
            ) {
              return -1;
            } else {
              return 0;
            }
          });

          if (propertybookings.length > 0) {
            let currentBookingIndex = propertybookings.findIndex(
              (f: Booking) => f.id === booking.id
            );
            if (
              currentBookingIndex !== -1 &&
              currentBookingIndex < propertybookings.length - 1
            ) {
              let nextBooking: Booking = propertybookings.find(
                (b: Booking, index: number) =>
                  index > currentBookingIndex && !b.cancelled
              );
              if (
                nextBooking &&
                nextBooking?.id &&
                nextBooking?.jobtype === "checkin"
              ) {
                nextBooking.previousbooking = new Booking();
                store.dispatch(
                  "diary/removePreviousBookingFromLink",
                  nextBooking,
                  { root: true }
                );
              }
              if (
                nextBooking?.jobtype === "checkin" &&
                (nextBooking?.internaljobtype == Booking.CIOFFACTREPORT ||
                  nextBooking?.internaljobtype == Booking.B2BCI)
              ) {
                nextBooking.connectedbooking = new Booking();
                store.dispatch("diary/saveConnectedBooking", nextBooking, {
                  root: true,
                });
                booking.connectedbooking = new Booking();
                store.dispatch("diary/saveConnectedBooking", booking, {
                  root: true,
                });
              }
            }
          }
        }
      })
      .then(async () => {
        if (booking.leadbooking?.id) {
          var leadbooking: Booking = booking.leadbooking;
          await store
            .dispatch("audit", {
              booking: new Booking(booking).leadbooking,
              operation: "cancel",
              subbooking: booking,
            })
            .then((b: Booking) => (leadbooking = b));
          leadbooking.removeSubbooking(booking);
        }
      })
      .then(() =>
        store.commit(
          "setBookings",
          _.remove(store.state.list, (c: Booking) => c.id !== booking.id)
        )
      )
      .then(() => store.commit("resetUnsavedChanges"))
      .finally(() =>
        store.commit("app/removeRequest", "cancelBooking", { root: true })
      );
  },

  /**
   * Restore a previously cancelled Booking
   *
   * @param {DiaryState} store
   * @param booking Booking
   * @returns void
   */
  async restoreBooking(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<Booking> {
    store.commit("app/addRequest", "restoreBooking", { root: true });
    await store
      .dispatch("audit", {
        booking: new Booking(booking),
        operation: "restore",
      })
      .then((b: Booking) => (booking = b));
    await API.put("RestAPI", `/bookings/restore/${booking.id}`, {})
      .then(async () => {
        let json = _pick(booking.toJSON(), [
          "auditlogs",
          "startdate",
          "enddate",
          "inspector",
          "address",
          "keypickup",
          "keypickupfromaddress",
          "releasekeysto",
          "keyreleasetoaddress",
        ]);
        await API.put("RestAPI", `/bookings/${booking.id}`, {
          body: json,
        });
      })
      .then(() => store.commit("resetUnsavedChanges"))
      .finally(() =>
        store.commit("app/removeRequest", "restoreBooking", { root: true })
      );
    return booking;
  },

  /**
   * Search getaddress for given postcode
   *
   * @param {DiaryState} store
   * @param postcode: string
   * @returns response
   */
  async searchGetaddressByPostcode(
    store: ActionContext<DiaryState, any>,
    postcode: string
  ): Promise<GetaddressResponse> {
    const options = {
      params: { "api-key": process.env.VUE_APP_GETADDRESS_API_KEY },
    };
    store.commit("app/addRequest", "searchGetaddressByPostcode", {
      root: true,
    });
    return axios
      .get(
        `https://api.getAddress.io/find/${postcode.replace(/\s/g, "")}`,
        options
      )
      .then((response: any) => {
        return new GetaddressResponse(response.data);
      })
      .then((response: GetaddressResponse) => {
        store.commit("setGetaddressresponse", response);
        return response;
      })
      .finally(() =>
        store.commit("app/removeRequest", "searchGetaddressByPostcode", {
          root: true,
        })
      );
  },

  /**
   * Search getaddress for given address
   *
   * @param {DiaryState} store
   * @param address: string
   * @returns response
   */
  async searchGetaddressByAddress(
    store: ActionContext<DiaryState, any>,
    address: string
  ): Promise<SearchAddressResponse> {
    const options = {
      queryStringParameters: {
        anytext: address,
      },
    };
    store.commit("app/addRequest", "searchGetaddressByAddress", { root: true });
    return await API.get("RestAPI", `/google/addresssearch`, options)
      .then((response: any) => new SearchAddressResponse(response))
      .finally(() =>
        store.commit("app/removeRequest", "restoreBooking", { root: true })
      );
  },

  setAddressDeep(store: ActionContext<DiaryState, any>, data): void {
    store.commit("setAddressDeep", data);
  },
  setPropertyspecDeep(store: ActionContext<DiaryState, any>, data): void {
    store.commit("setPropertyspecDeep", data);
  },
  setBookingDeep(store: ActionContext<DiaryState, any>, data): void {
    store.commit("setBookingDeep", data);
    if (data.path != "locked" && data.path != "lockedby")
      store.commit("addUnsavedChange");
  },

  /**
   * Send email
   *
   * @param {DiaryState} store
   * @param email: Email
   * @returns response
   */
  async sendEmail(
    store: ActionContext<DiaryState, any>,
    email: Email
  ): Promise<string> {
    store.commit("app/addRequest", "sendEmail", { root: true });
    let jsonBody = email.toJSON();
    let path = `/bookings/email`;
    if (email.attachments?.length > 0) path = `/bookings/emailwithattachments`;
    return API.post("RestAPI", path, { body: jsonBody })
      .then((response: string) => {
        return response;
      })
      .finally(() =>
        store.commit("app/removeRequest", "sendEmail", { root: true })
      );
  },

  /**
   * Send email to dataentry
   *
   * @param {DiaryState} store
   * @param email: Email
   * @returns response
   */
  async sendEmailToDataentry(
    store: ActionContext<DiaryState, any>,
    payload: { bookingid: string; chronorder: string }
  ): Promise<void> {
    store.commit("app/addRequest", "sendEmailToDataentry", { root: true });
    return API.post("RestAPI", "/emaildataentry", {
      body: { ...payload },
    }).finally(() =>
      store.commit("app/removeRequest", "sendEmailToDataentry", {
        root: true,
      })
    );
  },

  /**
   * Send request callback email
   *
   * @param {DiaryState} store
   * @param email: Email
   * @returns response
   */
  async sendRequestCallbackEmail(
    store: ActionContext<DiaryState, any>,
    bookingid: String
  ): Promise<any> {
    store.commit("app/addRequest", "sendRequestCallbackEmail", { root: true });
    return unAuthenticatedApiRequest(
      API,
      `/unauth/bookings/sendcallbackemail/${bookingid}`,
      "get",
      {}
    ).finally(() =>
      store.commit("app/removeRequest", "sendRequestCallbackEmail", {
        root: true,
      })
    );
  },

  /**
   * Get SMS logs
   *
   * @param {DiaryState} store
   * @param id
   * @returns emaillogs: EmailLogs
   */
  async getEmailLogs(store: ActionContext<DiaryState, any>, id) {
    // Search by Ref
    store.commit("app/addRequest", "getEmailLogs", { root: true });
    return API.get("RestAPI", `/bookings/${id}/emaillogs`, null)
      .then((response) => new Booking(response))
      .then((booking: Booking) => {
        store.commit("setEmailLogs", booking.emaillogs);
        return booking.emaillogs;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getEmailLogs", { root: true })
      );
  },

  /**
   * Send SMS
   *
   * @param {DiaryState} store
   * @param sms: SMS
   * @returns response
   */
  async sendSMS(
    store: ActionContext<DiaryState, any>,
    sms: SMS
  ): Promise<string> {
    store.commit("app/addRequest", "sendSMS", { root: true });
    let jsonBody = sms.toJSON();
    return API.post("RestAPI", `/bookings/sms`, { body: jsonBody })
      .then((response: string) => {
        return response;
      })
      .finally(() =>
        store.commit("app/removeRequest", "sendSMS", { root: true })
      );
  },

  /**
   * Get SMS logs
   *
   * @param {DiaryState} store
   * @param id
   * @returns smslogs: SMSLogs
   */
  async getSMSLogs(store: ActionContext<DiaryState, any>, id) {
    // Search by Ref
    store.commit("app/addRequest", "getSMSLogs", { root: true });
    return API.get("RestAPI", `/bookings/${id}/smslogs`, null)
      .then((response) => new SMSLogs(response))
      .then((smslogs: SMSLogs) => {
        store.commit("setSMSLogs", smslogs);
        return smslogs;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getSMSLogs", { root: true })
      );
  },

  /**
   * Get multiple Inspectors
   *
   * @param {DiaryState} store
   * @returns Multiple Inspectors
   */
  async getInspectors(
    store: ActionContext<DiaryState, any>
  ): Promise<Inspector[]> {
    store.commit("app/addRequest", "getInspectors", { root: true });

    return API.get("RestAPI", "/inspectors", {})
      .then((response) =>
        response.map((x: Partial<Inspector>) => new Inspector(x))
      )
      .then((inspectors: Inspector[]) => {
        store.commit("setInspectors", inspectors);
        return inspectors;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getInspectors", { root: true })
      );
  },

  /**
   * Get Inspectors by email
   *
   * @param {DiaryState} store
   * @returns Single Inspectors
   */
  async getInspectorByEmail(
    store: ActionContext<DiaryState, any>,
    email: string
  ): Promise<Inspector> {
    store.commit("app/addRequest", "getInspectorByEmail", { root: true });

    return API.get("RestAPI", `/inspectorsbyemail/${email}`, {})
      .then((response) => new Inspector(response))
      .then((inspector: Inspector) => {
        return inspector;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getInspectorByEmail", { root: true })
      );
  },

  /**
   * Add a Inspector
   *
   * @param {DiaryState} store
   * @param inspector Inspector
   * @returns Inspector
   */
  async addInspector(
    store: ActionContext<DiaryState, any>,
    inspector: Inspector
  ) {
    const newlist: Inspector[] = store.state.inspectorlist.concat(inspector);
    store.commit("setInspectors", newlist);
  },

  /**
   * Update a Inspector
   *
   * @param {DiaryState} store
   * @param inspector Inspector
   * @returns Updated Inspector
   */
  async updateInspector(
    store: ActionContext<DiaryState, any>,
    inspector: Inspector
  ): Promise<void> {
    _.remove(
      store.state.inspectorlist,
      (c: Inspector) => c.id === inspector.id
    );
    const newlist: Inspector[] = store.state.inspectorlist.concat(inspector);
    store.commit("setInspectors", newlist);
  },

  /**
   * Delete a Inspector
   *
   * @param {DiaryState} store
   * @param inspector Inspector
   * @returns Deleted Inspector
   */
  async deleteInspector(
    store: ActionContext<DiaryState, any>,
    inspector: Inspector
  ): Promise<void> {
    const newlist: Inspector[] = _.remove(
      store.state.inspectorlist,
      (i: Inspector) => i.id !== inspector.id
    );
    store.commit("setInspectors", newlist);
  },

  /**
   * Sort inspector list
   *
   * @param {DiaryState} store
   * @param bookingdate: string, postcode: string
   * @returns void
   */
  async sortInpectors(
    store: ActionContext<DiaryState, any>,
    params: {
      previousreport: Report;
      previousbooking: Booking;
      postcode: string;
    }
  ): Promise<void> {
    store.commit("app/addRequest", "sortInpectors", { root: true });
    let sortedlist = sortInspectorList(store.state.inspectorlist, params);
    store.commit("setInspectors", sortedlist);
    store.commit("app/removeRequest", "sortInpectors", { root: true });
  },

  /**
   * Get distancematrix
   *
   * @param {DiaryState} store
   * @param { from: string[], to: string[]}
   * @returns DistanceMatrixResponse
   */
  async getDistanceMatrix(
    store: ActionContext<DiaryState, any>,
    params: { from: string[]; to: string[]; mode: string }
  ): Promise<DistanceMatrixResponse> {
    const options = {
      queryStringParameters: {
        from: params.from.join("|"),
        to: params.to.join("|"),
        mode: params.mode,
      },
    };

    // Make sure from and to are present
    if (
      !options.queryStringParameters.from ||
      !options.queryStringParameters.to ||
      options.queryStringParameters.from === options.queryStringParameters.to
    ) {
      return new DistanceMatrixResponse();
    }
    // return DistanceMatrixResponse.getSample(
    //   options.queryStringParameters.from,
    //   options.queryStringParameters.to
    // );

    let matrix = await API.get("RestAPI", "/google/distancematrix", options)
      .then((response) => {
        let m = new DistanceMatrixResponse(response);
        return m;
      })
      .catch((e) => {
        console.log("Failed call to /google/distancematrix", e);
        return new DistanceMatrixResponse();
      });
    return matrix;
  },

  /**
   * Get travel time to given booking
   *
   * @param {DiaryState} store
   * @param {b1: Booking | Inspector, b2: Booking | Inspector}
   * @returns traveltime TravelTime
   */
  async getTraveltimeTo(
    store: ActionContext<DiaryState, any>,
    payload: { obj1: Booking | Inspector; obj2: Booking | Inspector }
  ): Promise<TravelTime> {
    var traveltime: TravelTime = new TravelTime();
    var origins: string[] | undefined = payload.obj1?.endpostcodes;
    var destinations: string[] | undefined = payload.obj2?.startpostcodes;
    let inspector = undefined;
    if (payload.obj2 instanceof Inspector) {
      inspector = payload.obj2;
    } else if (payload.obj2 instanceof Booking) {
      inspector = payload.obj2.inspector;
    }
    traveltime = await store.dispatch("getTraveltimeToPostcodes", {
      origins: origins,
      destinations: destinations,
      travelmode: inspector?.travelmode,
    });
    return traveltime;
  },

  /**
   * Get travel time to given booking
   *
   * @param {DiaryState} store
   * @param {b1: Booking | Inspector, b2: Booking | Inspector}
   * @returns traveltime TravelTime
   */
  async getTraveltimeToPostcodes(
    store: ActionContext<DiaryState, any>,
    payload: {
      origins: string[] | undefined;
      destinations: string[] | undefined;
      travelmode: string;
    }
  ): Promise<TravelTime> {
    var traveltime: TravelTime = new TravelTime();

    let totalroutes = 0;
    if (payload.origins && payload.destinations) {
      let routearray: string[] = payload.origins.concat(payload.destinations);
      traveltime.frompostcodes = routearray;
      totalroutes = routearray.length;
      payload.origins = routearray.filter(
        (o: string, i: number, array: string[]) => i != array.length - 1
      );
      payload.destinations = routearray.filter(
        (o: string, i: number, array: string[]) => i != 0
      );
    }
    if (
      payload.origins &&
      payload.destinations &&
      payload.origins.length &&
      payload.destinations.length
    ) {
      const params = {
        from: payload.origins,
        to: payload.destinations,
        mode: payload.travelmode,
      };
      await store
        .dispatch("getDistanceMatrix", params)
        .then((response: DistanceMatrixResponse) => {
          if (response.status === "OK") {
            if (totalroutes == 2) {
              traveltime.from =
                response.rows?.[0]?.elements?.[0]?.durationseconds > 0
                  ? response.rows?.[0]?.elements?.[0]?.durationseconds
                  : 0;
            } else if (totalroutes == 3) {
              traveltime.fromfirstleg =
                response.rows?.[0]?.elements?.[0]?.durationseconds > 0
                  ? response.rows?.[0]?.elements?.[0]?.durationseconds
                  : 0;
              traveltime.fromsecondleg =
                response.rows?.[1]?.elements?.[1]?.durationseconds > 0
                  ? response.rows?.[1]?.elements?.[1]?.durationseconds
                  : 0;
              traveltime.from =
                traveltime.fromfirstleg + traveltime.fromsecondleg;
            } else if (totalroutes == 4) {
              traveltime.fromfirstleg =
                response.rows?.[0]?.elements?.[0]?.durationseconds > 0
                  ? response.rows?.[0]?.elements?.[0]?.durationseconds
                  : 0;
              traveltime.fromsecondleg =
                response.rows?.[1]?.elements?.[1]?.durationseconds > 0
                  ? response.rows?.[1]?.elements?.[1]?.durationseconds
                  : 0;
              traveltime.fromthirdleg =
                response.rows?.[2]?.elements?.[2]?.durationseconds > 0
                  ? response.rows?.[2]?.elements?.[2]?.durationseconds
                  : 0;
              traveltime.from =
                traveltime.fromfirstleg +
                traveltime.fromsecondleg +
                traveltime.fromthirdleg;
            }
          }
        });
    }

    return traveltime;
  },

  /**
   * Get travel time
   *
   * @param {DiaryState} store
   * @param booking Booking
   * @returns traveltime TravelTime
   */
  async getTraveltime(
    store: ActionContext<DiaryState, any>,
    payload: {
      booking: Booking;
      previousbooking: Booking | undefined;
      nextbooking: Booking | undefined;
    }
  ): Promise<TravelTime> {
    var traveltime: TravelTime = new TravelTime();
    var previousbooking = payload.previousbooking;
    if (previousbooking) {
      await store
        .dispatch("getTraveltimeTo", {
          obj1: previousbooking,
          obj2: payload.booking,
        })
        .then((ftt: TravelTime) => {
          traveltime.frompostcodes = ftt.frompostcodes;
          traveltime.from = ftt.from;
          traveltime.fromfirstleg = ftt.fromfirstleg;
          traveltime.fromsecondleg = ftt.fromsecondleg;
          traveltime.fromthirdleg = ftt.fromthirdleg;
          var difference = differenceInSeconds(
            payload.booking.startdate,
            previousbooking?.enddate
          );
          if (traveltime.from > 0) {
            traveltime.fromdifference = difference - traveltime.from;
          }
        });
    }

    var nextbooking = payload.nextbooking;
    if (nextbooking) {
      await store
        .dispatch("getTraveltimeTo", {
          obj1: payload.booking,
          obj2: nextbooking,
        })
        .then((ttt: TravelTime) => {
          traveltime.topostcodes = ttt.frompostcodes;
          traveltime.to = ttt.from;
          traveltime.tofirstleg = ttt.fromfirstleg;
          traveltime.tosecondleg = ttt.fromsecondleg;
          traveltime.tothirdleg = ttt.fromthirdleg;
          var difference = differenceInSeconds(
            nextbooking?.startdate,
            payload.booking.enddate
          );
          if (traveltime.to > 0) {
            traveltime.todifference = difference - traveltime.to;
          }
        });
    }
    return traveltime;
  },

  /**
   * Get previous booking
   *
   * @param {DiaryState} store
   * @param id string
   * @returns booking Booking
   */
  async getPreviousBooking(
    store: ActionContext<DiaryState, any>,
    id: string
  ): Promise<Booking | undefined> {
    return await API.get("RestAPI", `/bookings/previous/${id}`, {}).then(
      (response) => {
        if (response) return new Booking(response);
        return undefined;
      }
    );
  },

  /**
   * Get next booking
   *
   * @param {DiaryState} store
   * @param id string
   * @returns booking Booking
   */
  async getNextBooking(
    store: ActionContext<DiaryState, any>,
    id: string
  ): Promise<Booking | undefined> {
    return await API.get("RestAPI", `/bookings/next/${id}`, {}).then(
      (response) => {
        if (response) return new Booking(response);
        else return undefined;
      }
    );
  },

  /**
   * Management booking search
   *
   * @param {DiaryState} store
   * @param managementBookingSearch ManagementBookingSearch
   * @returns bookings Booking[]
   */
  async managementBookingSearch(
    store: ActionContext<DiaryState, any>,
    params: any
  ): Promise<Booking[]> {
    store.commit("app/addRequest", "managementBookingSearch", { root: true });
    return await API.get("RestAPI", "/bookings/managementbookings", {
      queryStringParameters: params,
    })
      .then(async (response) => {
        const resultFileUrl = s3Origin(response);
        const s3Response = await axios.get(resultFileUrl);
        return s3Response.data;
      })
      .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
      .then((bookings: Booking[]) => {
        return bookings;
      })
      .finally(() =>
        store.commit("app/removeRequest", "managementBookingSearch", {
          root: true,
        })
      );
  },

  /**
   * Search bookings
   *
   * @param {DiaryState} store
   * @param params query string parameters
   * @returns bookings Booking[]
   */
  async searchBookings(
    store: ActionContext<DiaryState, any>,
    params: any
  ): Promise<Booking[]> {
    store.commit("app/addRequest", "searchBookings", { root: true });
    return await API.get("RestAPI", "/bookings/searchbookings", {
      queryStringParameters: params,
    })
      .then(async (response) => {
        const resultFileUrl = s3Origin(response);
        const s3Response = await axios.get(resultFileUrl);
        return s3Response.data;
      })
      .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
      .then((bookings: Booking[]) => {
        return bookings;
      })
      .finally(() =>
        store.commit("app/removeRequest", "searchBookings", {
          root: true,
        })
      );
  },

  /**
   * Address based booking search
   *
   * @param {DiaryState} store
   * @param addressBookingSearch ManagementBookingSearch
   * @returns bookings Booking[]
   */
  async addressBookingSearch(
    store: ActionContext<DiaryState, any>,
    params: any
  ): Promise<Booking[]> {
    store.commit("app/addRequest", "addressBookingSearch", { root: true });
    return await API.get("RestAPI", "/bookings/addresssearch", {
      queryStringParameters: params,
    })
      .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
      .then((bookings: Booking[]) => {
        return bookings;
      })
      .finally(() =>
        store.commit("app/removeRequest", "addressBookingSearch", {
          root: true,
        })
      );
  },

  /**
   * Get google calendar events
   *
   * @param {DiaryState} store
   * @returns bookings Booking[]
   */
  async getGoogleCalendarBookings(
    store: ActionContext<DiaryState, any>,
    payload: { fromdate: string; todate: string; anytext: string }
  ): Promise<Booking[]> {
    store.commit("app/addRequest", "getGoogleCalendarBookings", { root: true });
    const options = {
      queryStringParameters: _.pickBy(
        _.pick(payload, ["fromdate", "todate", "anytext"])
      ),
    };

    if (!payload.fromdate || !payload.todate) {
      store.commit("app/removeRequest", "getGoogleCalendarBookings", {
        root: true,
      });
      return [];
    }
    return API.get("RestAPI", `/google/calendar/bookings`, options)
      .then((response) => response.map((x: Partial<Booking>) => new Booking(x)))
      .then((bookings: Booking[]) => {
        if (bookings && bookings.length >= 0) {
          const inspectors: Inspector[] = store.getters.inspectorlist;
          bookings.forEach((b: Booking) => {
            if (b.googlecalendaremail && inspectors) {
              const inspector = inspectors.find(
                (i: Inspector) =>
                  i.googlecalendaremail === b.googlecalendaremail
              );
              if (inspector) {
                b.inspector = inspector;
              }
            }
          });
        }
        return bookings;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getGoogleCalendarBookings", {
          root: true,
        })
      );
  },

  /**
   * Generate "Booking Confirmation Note" PDF (Uses Job polling)
   */
  async generateConfirmationNotePdf(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<any> {
    const { attachments, id } = booking;

    try {
      // Does this attachment exist in Vuex..?
      if (attachments.clientconfirmation) {
        return attachments.clientconfirmation;
      }

      const generateEndpoint = `/bookings/${id}/generate/client-confirmation`;
      const downloadEndpoint = `/bookings/${id}/download/client-confirmation`;

      const key = await bookingAttachmentJob(
        generateEndpoint,
        downloadEndpoint
      );

      if (key) {
        // Set the S3 Key for the attachment
        booking.attachments.clientconfirmation = key;

        // Update Booking in Vuex
        updateBookingInStore(store, booking);

        return key;
      } else {
        throw new Error("Unable to generate Client Confirmation PDF");
      }
    } catch (err) {
      throw new Error((err as any).message);
    }
  },

  /**
   * Get multiple Customers
   *
   * @param {CustomersState} store
   * @param filters
   * @returns Multiple Customers
   */
  async getAllCustomers(
    store: ActionContext<DiaryState, any>,
    filters: any
  ): Promise<Customer[]> {
    const options = {
      queryStringParameters: {
        fieldlist: encodeURI(
          "company_name,branch_name,address,hqbranch,ac_client_code,accountsettings,reportsettings,pricelist,qcrules,policies,office,editable"
        ),
      },
    };

    store.commit("app/addRequest", "getAllCustomers", { root: true });

    return API.get("RestAPI", "/customers", options)
      .then((response) =>
        response.map((x: Partial<Customer>) => new Customer(x))
      )
      .then((customers: Customer[]) => {
        store.commit("setCustomers", customers);
        return customers;
      })
      .finally(() =>
        store.commit("app/removeRequest", "getAllCustomers", { root: true })
      );
  },

  /**
   * Get Company Name options
   *
   * - Used for select tag options
   * @param {CustomersState} store
   * @returns Company Name options
   */
  async getCompanyNameOptions(
    store: ActionContext<DiaryState, any>,
    managedtype?: string
  ) {
    const list = store.state.customerlist
      .filter((c: Customer) => {
        if (managedtype === "Non-managed")
          return c.acClientCode === "NM" || c.acClientCode === "MISC";
        else if (managedtype === "Managed")
          return c.acClientCode != "NM" && c.acClientCode != "MISC";
        else return true;
      })
      .map((c: Customer) => c.companyName);
    return [...new Set(list)].sort();
  },

  /**
   * Get Branch Name options - filter by managedtype
   *
   * - Used for select tag options
   * @param {CustomersState} store
   * @param payload: {company_name :string, managedtype: string}
   * @returns Branch Name options
   */
  async getBranchNameOptionsWithManagedtype(
    store: ActionContext<DiaryState, any>,
    payload: { company_name: string; managedtype: string }
  ): Promise<any> {
    const list = store.state.customerlist
      .filter((c: Customer) => {
        if (payload.company_name) return c.companyName === payload.company_name;
        else return true;
      })
      .filter((c: Customer) => {
        if (payload.managedtype === "Non-managed")
          return c.acClientCode === "NM" || c.acClientCode === "MISC";
        else if (payload.managedtype === "Managed")
          return c.acClientCode != "NM" && c.acClientCode != "MISC";
        else return true;
      })
      .map((c: Customer) => c.branchName);
    return [...new Set(list)].sort();
  },

  /**
   * Get multiple Customers, bypassing the store (used by diary)
   *  the only difference between this and the previous is, this one
   *  does not update the store.
   *
   * @param {CustomersState} store
   * @param filters
   * @returns Multiple Customers
   */
  async getCustomers(
    store: ActionContext<DiaryState, any>,
    filters: any
  ): Promise<Customer[]> {
    return store.state.customerlist
      .filter((c: Customer) => {
        if (filters.company_name) return c.companyName === filters.company_name;
        else return true;
      })
      .filter((c: Customer) => {
        if (filters.branch_name) return c.branchName === filters.branch_name;
        else return true;
      })
      .filter((c: Customer) => {
        if (filters.managedtype === "Non-managed")
          return c.acClientCode === "NM" || c.acClientCode === "MISC";
        else if (filters.managedtype === "Managed")
          return c.acClientCode != "NM" && c.acClientCode != "MISC";
        else return true;
      });
  },

  /**
   * Update a Customer POC
   *
   * @param {DiaryState} store
   * @param customer Customer
   * @returns Updated Customer
   */
  async updateCustomerPOC(
    store: ActionContext<DiaryState, any>,
    payload: {
      customerid: string;
      newcontacts: Contact[];
      updatedcontacts: Contact[];
    }
  ): Promise<Customer> {
    store.commit("app/addRequest", "updateCustomerPOC", { root: true });
    return API.put("RestAPI", `/customerpoc/${payload.customerid}`, {
      body: {
        contactlist: payload.newcontacts,
        updatedcontacts: payload.updatedcontacts,
      },
    })
      .then((response) => new Customer(response))
      .then((customer: Customer) => {
        store.commit("setCustomer", customer);

        const index = store.state.customerlist.findIndex(
          (c) => c.id === customer.id
        );
        if (index >= 0) {
          const newlist = [...store.state.customerlist];
          newlist.splice(index, 1);
          newlist.push(customer);
          store.commit("setCustomers", newlist);
        }
        store.commit("resetUnsavedChanges");
        return customer;
      })
      .finally(() =>
        store.commit("app/removeRequest", "updateCustomerPOC", { root: true })
      );
  },

  /**
   * Mark sms/email messages as read
   *
   * @param {DiaryState} store
   * @param customer Customer
   * @returns Updated Customer
   */
  async markSMSEmailAsRead(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ) {
    store.commit("app/addRequest", "markSMSEmailAsRead", { root: true });
    API.put("RestAPI", `/bookings/markmessageasread`, {
      body: booking.toJSON(),
    })
      .then((response) => new Booking(response))
      .finally(() =>
        store.commit("app/removeRequest", "markSMSEmailAsRead", { root: true })
      );
  },

  hasUnsavedChanges(store: ActionContext<DiaryState, any>): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      resolve(store.state.updates.length > 0);
    });
  },

  /**
   * Lock a Booking
   */
  async lockBooking(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<Booking | void> {
    if (booking) {
      store.commit("app/addRequest", "lockBooking", { root: true });
      return API.put("RestAPI", `/lockbooking/${booking.id}/true`, {})
        .then((data) => new Booking(data))
        .then((b: Booking) => {
          if (store.state.booking.id === booking.id) {
            store.commit("setBookingDeep", {
              path: "locked",
              data: b.locked,
            });
            store.commit("setBookingDeep", {
              path: "lockedby",
              data: b.lockedby,
            });
          }
          return b;
        })
        .catch((e: any) => console.log(e.response.data.message))
        .finally(() =>
          store.commit("app/removeRequest", "lockBooking", { root: true })
        );
    }
  },

  /**
   * Unlock a Booking
   */
  async unlockBooking(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<Booking | void> {
    if (booking) {
      store.commit("app/addRequest", "unlockBooking", { root: true });
      return API.put("RestAPI", `/lockbooking/${booking.id}/false`, {})
        .then((data) => new Booking(data))
        .then((b: Booking) => {
          if (store.state.booking.id === booking.id) {
            store.commit("setBookingDeep", {
              path: "locked",
              data: false,
            });
            store.commit("setBookingDeep", {
              path: "lockedby",
              data: "",
            });
          }
          return b;
        })
        .catch((e: any) => console.log(e.response.data.message))
        .finally(() =>
          store.commit("app/removeRequest", "unlockBooking", { root: true })
        );
    }
  },

  /**
   * Mark Booking as inprogress
   */
  async bookingInprogress(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<Booking | void> {
    if (booking) {
      store.commit("app/addRequest", "bookingInprogress", { root: true });
      return API.put("RestAPI", `/bookinginprogress/${booking.id}/true`, {})
        .then((data) => new Booking(data))
        .then((b: Booking) => {
          if (store.state.booking.id === booking.id) {
            store.commit("setBookingDeep", {
              path: "inprogress",
              data: b.inprogress,
            });
          }
          return b;
        })
        .catch((e: any) => console.log(e.response.data.message))
        .finally(() =>
          store.commit("app/removeRequest", "bookingInprogress", { root: true })
        );
    }
  },

  /**
   * Mark booking not in progress
   */
  async bookingNotinprogress(
    store: ActionContext<DiaryState, any>,
    booking: Booking
  ): Promise<Booking | void> {
    if (booking) {
      store.commit("app/addRequest", "bookingNotinprogress", { root: true });
      return API.put("RestAPI", `/bookinginprogress/${booking.id}/false`, {})
        .then((data) => new Booking(data))
        .then((b: Booking) => {
          if (store.state.booking.id === booking.id) {
            store.commit("setBookingDeep", {
              path: "inprogress",
              data: false,
            });
          }
          return b;
        })
        .catch((e: any) => console.log(e.response.data.message))
        .finally(() =>
          store.commit("app/removeRequest", "bookingNotinprogress", {
            root: true,
          })
        );
    }
  },
};
