import _get from 'lodash/get';
import _castArray from 'lodash/castArray';

import { Base, DepositoryDeduction } from '@/models';
import { toCamelCase } from '@/utilities';

export class Note extends Base {

  public note: string;

  public originalnote: string;

  public flagged: boolean;

  public responsibility?: string[];

  public depositoryDeductions: DepositoryDeduction[];

  public cleanliness_flag: boolean;

  /**
   * Constructor initialises the variables from the class Note
   * @param data - the data to construct a type with all properties of <Note>
   */
  public constructor(data?: Partial<Note>) {
    super(data);
    data = toCamelCase(data);

    console.log('Note data:', data);

    this.note = _get(data, 'note', '');
    this.originalnote = _get(data, 'originalnote', '');
    this.flagged = _get(data, 'flagged', false);

    if (_get(data, 'responsibility')) {
      this.responsibility = _get(data, 'responsibility');
    }

    if (_get(data, 'depositoryDeductions')) {
      this.depositoryDeductions = _castArray(_get(data, 'depositoryDeductions')).map((x: any) => new DepositoryDeduction(x));
    }

    this.cleanliness_flag = _get(data, 'cleanlinessFlag', false);
    console.log('cleanliness_flag', this.cleanliness_flag);
  }

}
