<template>
  <div
  :id="id"
    class="modal"
    data-keyboard="true"
    tabindex="-1"
    role="dialog"
    aria-labelledby="`fixedtime-booking-modal-label`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="fixedtime-booking-modal-label">Show OTP Modal</h5>
          <button
            id="close"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click.prevent="hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body text-center">
  <label class="col-form-label">
    <b class="d-block fs-4 text-primary">Your OTP : {{ otp }}</b>
    <p class="mt-2 text-danger fs-5">
      <b>OTP expires in : {{ remainingTime }} seconds</b>
    </p>
  </label>
</div>


        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-outline-secondary"
            data-dismiss="modal"
            @click.prevent="hide()"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import $ from 'jquery';
import { ref, defineEmits, defineExpose,defineProps } from 'vue';
const otp = ref(""); 
const expire = ref(0); 
const emit = defineEmits(['hide'])
const props = defineProps<{
  id: string;
}>();

const init = (otpvalue:string,expirevalue:number) => {
  otp.value = otpvalue;
  expire.value = expirevalue;
  startCountdown(expire.value);
};

const show = () => {
  if ($(`#${props.id}` + 'Backdrop').length == 0) {
    const backdropDiv = $('<div class="modal-backdrop fade show" id="' + props.id + 'Backdrop"></div>');
    $('body').append(backdropDiv);
    $(`#${props.id}`).show();
  }
}
const hide = () => {
  if ($(`#${props.id}`).length > 0) {
    $(`#${props.id}` + 'Backdrop').remove();
    $(`#${props.id}`).hide();
  }
};
let countdownInterval: any = null; // Store interval reference
const remainingTime = ref(60); // Track remaining time
const startCountdown = (expiryTimestamp: number) => {
  if (countdownInterval) clearInterval(countdownInterval); // Clear previous timer if exists

  countdownInterval = setInterval(() => {
    const now = Date.now();
    const remaining = Math.max(0, Math.floor((expiryTimestamp - now) / 1000)); // Calculate remaining seconds

    remainingTime.value = remaining; // Update the reactive value

    if (remaining <= 0) {
      clearInterval(countdownInterval); // Stop timer when expired
    }
    if(remainingTime.value === 0){
      hide();
    }
  }, 1000);
};
defineExpose({init,show,hide});
</script>
